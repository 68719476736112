import React from 'react'
import { Box, Grid, Link, Typography } from '@material-ui/core'
import Heading from '../../components/Heading'
import Logotype from '../../components/Logotype'

const LogoSection = () => {
  return (
    <Box py={5}>
      <Heading text="FG LOGO OLUŞTURUCU" />
      <Box mb={4}>
        <Typography variant="body2">
          Hızlı, kolay ve hatta eğlenceli bir şekilde logonuzu oluşturun!
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item md={12} lg={6}>
          <Logotype />
        </Grid>
        <Grid item md={12} lg={6}>
          <Logotype negative />
        </Grid>
      </Grid>
    </Box>
  )
}

export default LogoSection
