export const icons = [
  'qr_code',
  'fingerprint',
  'store_mall_directory',
  'storefront',
  'gesture',
  'how_to_vote',
  'print',
  'weekend',
  'school',
  'hearing',
  'filter_drama',
  'support_agent',
  'add_shopping_cart',
  'scatter_plot',
  'security',
  'anchor',
  'all_inbox',
  'memory',
  'admin_panel_settings',
  'directions_boat',
  'build_circle',
  'directions_car',
  'moped',
  'pedal_bike',
  'directions_bike',
  'agriculture',
  'commute',
  'favorite_border',
  'language',
  'opacity',
  'pets',
  'sports_esports',
  'videogame_asset',
  'sports_volleyball',
  'sports_soccer',
  'sports_football',
  'stars',
  'settings_voice',
  'thumb_up',
  'wifi_protected_setup',
  'volume_up',
  'business',
  'apartment',
  'child_friendly',
  'biotech',
  'drafts',
  'save',
  'access_alarm',
  'headset',
  'toys',
  'audiotrack',
  'color_lens',
  'linked_camera',
  'timelapse',
  'remove_red_eye',
  'style',
  'wine_bar'
  // Maps: https://material.io/resources/icons/?icon=add_business&style=baseline
]